@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background: rgba(229, 231, 235, 0.49);
}

@media only screen and (min-width: 769px) {
    .popup-content {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        width: 39% !important;
        background: white;
    }
}

@media only screen and (max-width: 768px) {
    .popup-content {
        width: 80% !important;
        background: white;
    }
}
